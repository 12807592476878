import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=a2c1fc26&scoped=true&"
import script from "./layout.vue?vue&type=script&lang=js&"
export * from "./layout.vue?vue&type=script&lang=js&"
import style0 from "./layout.vue?vue&type=style&index=0&id=a2c1fc26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c1fc26",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox})
