<template>
  <c-flex flex-dir="column">
    <HeaderComp
      :logo="'hide'"
      :back="isBack"
      :back-title="backTitle"
    />

    <c-box
      :max-w="['inherit', '1200px']"
      w="100%"
      mx="auto"
      :px="['20px', null]"
      :pt="['20px', '60px']"
      :pb="['20px', '100px']"
    >
      <c-breadcrumb
        separator="›"
        :d="['none', 'block']"
      >
        <c-breadcrumb-item>
          <c-breadcrumb-link
            as="router-link"
            to="/"
          >
            Beranda
          </c-breadcrumb-link>
        </c-breadcrumb-item>
        <c-breadcrumb-item>
          <c-breadcrumb-link
            as="router-link"
            to="/profile"
          >
            Akun
          </c-breadcrumb-link>
        </c-breadcrumb-item>
      </c-breadcrumb>

      <c-flex
        justify-content="center"
        max-w="1200px"
      >
        <ProfileNavBottom v-if="isClient && $route.name !== 'client.profile'" />
        <c-flex
          :mt="[null, '55px']"
          :ml="isClient ? [null, '20px'] : ''"
          :w="isClient ? ['100%', '850px'] : ''"
          :flex-grow="!isClient ? 1 : ''"
        >
          <router-view />
        </c-flex>
      </c-flex>
    </c-box>

    <NavBottom
      v-if="(isClient || isNutri) && $route.name === 'client.profile'"
    />
    <FooterComp />
  </c-flex>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import ProfileNavBottom from './nav-bottom.vue'
import NavBottom from '@/components/nav-bottom'
import FooterComp from '@/components/layouts/footer/index.vue'
import { mapState } from 'vuex'
export default {
  name: 'ProfileLayout',
  components: {
    HeaderComp,
    ProfileNavBottom,
    NavBottom,
    FooterComp,
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isClient() {
      if (this.user && this.user.role == 'client') return true
      return false
    },
    isNutri() {
      if (this.user && this.user.role == 'nutritionist') return true
      return false
    },
    isBack() {
      if (
        this.$route.name === 'client.profile.detail' ||
        this.$route.name === 'client.profile.data-diet' ||
        this.$route.name === 'client.profile.program' ||
        this.$route.name === 'client.profile.historyTransaction'
      ) {
        return 'client.profile'
      }
      if (this.$route.name === 'client.profile.settings') {
        return 'Pengaturan'
      }
      return false
    },
    backTitle() {
      if (
        this.$route.name === 'client.profile.detail' ||
        this.$route.name === 'client.profile.data-diet' ||
        this.$route.name === 'client.profile.program' ||
        this.$route.name === 'client.profile.historyTransaction'
      ) {
        return 'Lihat Profile'
      }
      if (this.$route.name === 'client.profile.settings') {
        return 'Pengaturan'
      }
      return 'Profile'
    },
  },
}
</script>

<style scoped>
[data-chakra-component="CBreadcrumb"] {
  color: var(--colors-primary-400);
}

[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"] {
  color: var(--colors-primary-400);
}
[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"]:last-child {
  color: #333;
}
</style>
